import { CCard, CCardBody, CCol, CRow } from "@coreui/react-pro";
import IntervalSwitch from "./IntervalSwitch";
import { Order } from "src/types/Order";
import { useAddPlanToCartMutation } from "src/services/CartService";

interface PlanProps {
  cart: Order;
}

const Plan = ({ cart }: PlanProps) => {
  const plan = cart?.items.find(
    (item) => item.orderable_type == "App\\Models\\Plan"
  );

  const [addPlanToCart] = useAddPlanToCartMutation();

  const handleOnChangeInterval = async (interval: number) => {
    try {
      await addPlanToCart({
        cartId: cart.id,
        planId: plan?.orderable_id,
        interval: interval,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const discount =
    plan &&
    plan.orderable &&
    plan.orderable.price_month &&
    plan.orderable.price_year
      ? plan?.orderable?.price_month * 12 - plan?.orderable?.price_year
      : 0;

  const discountInProcents = plan?.orderable.price_year
    ? Math.round((discount * 100) / plan?.orderable?.price_year)
    : 0;
  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md={6}>
            <h5>Wybrany plan</h5>
          </CCol>
          <CCol md={6} className="text-end">
            <IntervalSwitch
              interval={plan?.meta.interval}
              discount={discountInProcents}
              onChange={handleOnChangeInterval}
            />
          </CCol>
          <CCol md={12}>
            <CCard className="mt-4">
              <CCardBody className="p-3">
                <CRow>
                  <CCol md={6} className="my-auto">
                    {plan?.name}
                  </CCol>
                  <CCol className="text-end" md={6}>
                    {plan?.price} zł <br />
                    <small className="text-muted">
                      / {plan?.meta.interval == 2 ? "miesiąc" : "rok"}
                    </small>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default Plan;
