import {
  CFormCheck,
  CFormInput,
  CFormLabel,
  CInputGroup,
} from "@coreui/react-pro";
import { useFormContext } from "react-hook-form";
import { Plan } from "src/types/Plan";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps) => {
  const methods = useFormContext<Plan>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CFormLabel className="mt-3">Nazwa</CFormLabel>
      <CFormInput
        {...register("name")}
        invalid={!!errors.name}
        feedbackInvalid={errors.name?.message}
      />
      <CFormCheck
        {...register("public")}
        label="Czy publiczny?"
        className="mt-3"
      />

      <CFormLabel className="mt-3">Cena miesięczna</CFormLabel>
      <CFormInput
        {...register("price_month")}
        invalid={!!errors.price_month}
        feedbackInvalid={errors.price_month?.message}
      />

      <CFormLabel className="mt-3">Cena roczna</CFormLabel>
      <CFormInput
        {...register("price_year")}
        invalid={!!errors.price_month}
        feedbackInvalid={errors.price_month?.message}
      />
      <CFormLabel className="mt-3">Ilość podstron</CFormLabel>
      <CFormInput
        {...register("website_params.pages_count")}
        invalid={!!errors.price_month}
        feedbackInvalid={errors.price_month?.message}
      />

      <CFormLabel className="mt-3">Miejsce na maile</CFormLabel>
      <CInputGroup>
        <CFormInput
          {...register("website_params.mail_quota")}
          invalid={!!errors.price_month}
          feedbackInvalid={errors.price_month?.message}
        />
        <span className="input-group-text">MB</span>
      </CInputGroup>

      <CFormLabel className="mt-3">Miejsce na dysku</CFormLabel>
      <CInputGroup>
        <CFormInput
          {...register("website_params.storage_quota")}
          invalid={!!errors.price_month}
          feedbackInvalid={errors.price_month?.message}
        />
        <span className="input-group-text">MB</span>
      </CInputGroup>
    </form>
  );
};

export default Form;
