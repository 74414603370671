import {
  CBadge,
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react-pro";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DataTable, { TableBadge } from "src/components/DataTable/DataTable";
import {
  useDeleteServiceMutation,
  useServicesQuery,
} from "src/services/ServiceService";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import { DefaultMeta } from "src/types/api/ListMeta";
import Swal from "sweetalert2";
import constants from "src/constants";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const { currentData: servicesResponse, isFetching } =
    useServicesQuery(params);
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const [deleteService] = useDeleteServiceMutation();

  const handleOnDelete = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteService(id);
      }
    });
  };

  const getTypeName = (type: number) => {
    switch (type) {
      case constants.SERVICE_TYPE_STANDARD:
        return (
          <span className="badge text-bg-success text-white">Standard</span>
        );
      case constants.SERVICE_TYPE_RECURING:
        return (
          <span className="badge text-bg-primary text-white">Cykliczna</span>
        );
      case constants.SERVICE_TYPE_PER_PAGE:
        return (
          <span className="badge text-bg-warning text-white">Za stronę</span>
        );
    }
  };

  const getPrice = (row: any) => {
    switch (row.type) {
      case constants.SERVICE_TYPE_STANDARD:
        return <>{row.price}</>;
      case constants.SERVICE_TYPE_RECURING:
        return (
          <>
            {row.price_month} mies / {row.price_year} rok
          </>
        );
      case constants.SERVICE_TYPE_PER_PAGE:
        return <>{row.price_per_page} za stronę</>;
    }
  };

  const columns = [
    { field: "name", headerName: "Nazwa", flex: 1 },
    {
      field: "public",
      headerName: "Publiczna",
      flex: 1,
      renderCell: (params: any) => <TableBadge value={params.value} />,
    },
    {
      field: "type",
      headerName: "Typ usługi",
      flex: 1,
      renderCell: ({ row }: any) => getTypeName(row.type),
    },
    {
      field: "price",
      headerName: "Cena",
      flex: 1,
      renderCell: ({ row }: any) => getPrice(row),
    },

    {
      field: "actions",
      headerName: "Akcje",
      width: 126,
      renderCell: ({ row: { id, status } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <Link
              to={`/services/${id}/edit`}
              className="btn btn-primary"
              color="primary"
            >
              Edytuj
            </Link>
            <button
              className="btn btn-danger text-white"
              onClick={() => handleOnDelete(id)}
            >
              Usuń
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <Link to="/services/create" className="btn btn-primary">
            Dodaj nową usługę
          </Link>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
