import { cilBell } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CButton,
  CCol,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeaderNav,
  CNavItem,
  CNavLink,
  CRow,
  CToastBody,
  CToastHeader,
} from "@coreui/react-pro";
import { useNotificationsQuery, useReadNotificationMutation } from "src/services/NotificationService";
import './Notifications.scss';
import { useState } from "react";
import { DefaultListParams } from "src/types/api/ListParams";
import moment from "moment";
import 'moment/locale/pl';
import { iconsMap } from "src/helpers/iconsMap";

const Notifications = () => {

  const [markAsRead] = useReadNotificationMutation();
  const [params, setParams] = useState(DefaultListParams);
  const { data: notificationsResponse } = useNotificationsQuery(params);
  const loadCount = 10;

  const notifications = notificationsResponse?.data;
  const meta = notificationsResponse?.meta;
  const unread = meta?.unread
  const handleOnClick = async (e, notification) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await markAsRead(notification.id).unwrap();
    } catch (e) {

    }
  }

  const loadMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setParams({ ...params, per_page: params.per_page + loadCount })

  }

  const formatDate = (date) => {
    return moment(date).locale('pl').fromNow();
  }


  return <CHeaderNav className="ms-auto">
    <CNavItem className="position-relative">
      <CNavLink href="#">
        <CDropdown variant="nav-item" alignment="end" autoClose="false">
          <CDropdownToggle
            placement="bottom-end"
            className="py-0 pe-0"
            caret={false}
          >
            <CButton className="position-relative">
              <CIcon icon={cilBell} size="lg" />
              {!!unread &&
                <CBadge
                  color="danger"
                  position="top-end"
                  shape="rounded-pill top-0 left-0"
                >{unread}
                  <span className="visually-hidden">unread messages</span>
                </CBadge>
              }
            </CButton>
          </CDropdownToggle>
          <CDropdownMenu
            className="pt-0"
            placement="bottom-end"
            style={{ width: "40em" }}
          >
            <div className="position-relative scrolled-list">
              <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">
                Powiadomienia
              </CDropdownHeader>
              {notifications?.map((notification) => (

                <CDropdownItem
                  href="#"
                  onClick={(e) => handleOnClick(e, notification)}
                  className={'border-bottom ' + (notification.read_at == null ? 'unread' : '')} key={notification.id}>
                  <div >
                    <CRow>
                      <CCol>
                        <h6 className="notification-title"><CIcon icon={iconsMap[notification.data.icon]} className="mr-1" width={20} height={20} style={{ color: notification.data.icon_color }} />{notification.data.title}</h6>
                      </CCol>
                      <CCol className="text-end">
                        <small className="notification-date">{formatDate(notification.data.created_at)}</small>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <div className="notification-content">{notification.data.content}</div></CCol>
                    </CRow>
                  </div>
                </CDropdownItem>

              ))}
              <CDropdownItem disabled={meta?.current_page == meta?.last_page} className="text-center" href="#">
                <div onClick={loadMore}>
                  Załaduj więcej
                </div>


              </CDropdownItem>
            </div>
          </CDropdownMenu>
        </CDropdown>
      </CNavLink>
    </CNavItem>
  </CHeaderNav >;
};

export default Notifications;
