import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "./scss/style.scss";

// We use those styles to show code examples, you should remove them in your application.
import "./scss/examples.scss";

import WebsiteIndex from "src/views/websites/Index";
import WebsiteShow from "src/views/websites/Show";
import WebsiteBackups from "src/views/websites/Backups";

import EmailIndex from "src/views/emails/Index";
import EmailCreate from "src/views/emails/Create";
import EmailUpdate from "src/views/emails/Update";

import DomainIndex from "src/views/domains/Index";
import DomainCreate from "src/views/domains/Create";

import ServiceIndex from "src/views/services/Index";
import ServiceUpdate from "src/views/services/Update";
import ServiceCreate from "src/views/services/Create";

import PlanIndex from "src/views/plans/Index";
import PlanUpdate from "src/views/plans/Update";
import PlanCreate from "src/views/plans/Create";

import PlanGenerator from "src/views/plans/Generator";
import OrderIndex from "src/views/orders/Index";
import OrderShow from "src/views/orders/Show";

import TemplateIndex from "src/views/templates/Index";
import TemplateUpdate from "src/views/templates/Update";
import TemplateCreate from "src/views/templates/Create";

import DomainPricesIndex from "src/views/domain-prices/Index";
import DomainPricesUpdate from "src/views/domain-prices/Update";
import DomainPricesCreate from "src/views/domain-prices/Create";
import Settings from "src/views/settings/Index";
import Login from "./views/pages/login/Login";
import { Logout } from "./components/Logout";

import DefaultLayout from "./layout/DefaultLayout";

// Pages
import Register from "./views/pages/register/Register";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import { Cart } from "./views/cart/Cart";
import { Items } from "./views/cart/Items";
import AddPlanToCart from "./views/cart/AddPlanToCart";

import ThankYou from "./views/cart/ThankYou";
import Ovh from "./views/ovh/Ovh";
import AddDomainToCart from "./views/cart/AddDomainToCart";
import Password from "./views/pages/passowrd/Password";
import PasswordReset from "./views/pages/passwordReset/PasswordReset";
import OAuth from "./views/pages/oauth/OAuth";
import Notifier from "./components/Notifier";
import "./App.scss";
import Submit from "./views/cart/Submit";
import Payment from "./views/cart/Payment";

const App = () => {
  return (
    <>
      <Notifier />
      <BrowserRouter>
        <Routes>
          <Route path="/oauth" element={<OAuth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/password" element={<Password />} />
          <Route path="/password/:token" element={<PasswordReset />} />
          <Route path="/register" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />

          <Route path="/" element={<DefaultLayout />}>
            <Route path="websites" element={<WebsiteIndex />}>
              <Route path=":id" element={<WebsiteShow />} />
              <Route path=":id/backups" element={<WebsiteBackups />} />
            </Route>
            <Route path="websites/:websiteId/domains" element={<DomainIndex />}>
              <Route path="create" element={<DomainCreate />} />
            </Route>
            <Route path="/websites/:websiteId/emails" element={<EmailIndex />}>
              <Route path="create" element={<EmailCreate />}></Route>
              <Route path=":id/edit" element={<EmailUpdate />}></Route>
            </Route>

            <Route path="services" element={<ServiceIndex />}>
              <Route path=":id/edit" element={<ServiceUpdate />} />
              <Route path="create" element={<ServiceCreate />} />
            </Route>
            <Route path="plans" element={<PlanIndex />}>
              <Route path=":id/edit" element={<PlanUpdate />} />
              <Route path="create" element={<PlanCreate />} />
              <Route path="generator" element={<PlanGenerator />} />
            </Route>
            <Route path="plans/generator" element={<PlanGenerator />} />

            <Route path="orders" element={<OrderIndex />}>
              <Route path=":id" element={<OrderShow />} />
            </Route>
            <Route path="templates" element={<TemplateIndex />}>
              <Route path=":id/edit" element={<TemplateUpdate />} />
              <Route path="create" element={<TemplateCreate />} />
            </Route>
            <Route path="domain-prices" element={<DomainPricesIndex />}>
              <Route path=":id/edit" element={<DomainPricesUpdate />} />
              <Route path="create" element={<DomainPricesCreate />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route path="ovh" element={<Ovh />} />
            <Route index element={<Navigate to="websites" replace />} />
          </Route>
          <Route path="cart" element={<Cart />}>
            <Route index element={<Items />} />
            <Route path="submit" element={<Submit />} />
            <Route path="plan" element={<AddPlanToCart />} />
            <Route path="domain" element={<AddDomainToCart />} />

            <Route path="payment/:orderId" element={<Payment />} />
            <Route path="payment/:orderId/thank-you" element={<ThankYou />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
