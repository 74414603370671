import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarToggler,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logo } from 'src/assets/brand/logo'
import { sygnet } from 'src/assets/brand/sygnet'


import { userNav, adminNav } from '../_nav'
import { useMeQuery } from 'src/services/AuthService'
import { set } from 'src/store/reducers/sidebar'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const { currentData: me } = useMeQuery();


  const navigation = [...userNav, ...(me?.data?.admin ? adminNav : [])]
  const showSidebar = useSelector(state => state.showSidebar) ?? true;



  return (
    <CSidebar
      className="border-end"
      position="fixed"
      unfoldable={showSidebar}
      colorScheme='dark'

    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand to="/">
          <CIcon customClassName="sidebar-brand-full" icon={logo} height={32} />
          <CIcon customClassName="sidebar-brand-narrow" icon={sygnet} height={32} />
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch({ type: 'set', sidebarShow: false })}
        />
      </CSidebarHeader>
      <AppSidebarNav items={navigation} />
      <CSidebarFooter className="border-top d-none d-lg-flex">
        <CSidebarToggler
          onClick={() => dispatch(set(!showSidebar))}
        />
      </CSidebarFooter>
    </CSidebar>
  )
}

export default AppSidebar;
