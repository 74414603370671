import { Domain } from "src/types/Domain";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Email } from "src/types/Email";
import { Order } from "src/types/Order";
import { ListParams } from "src/types/api/ListParams";
import { PayResponse } from "src/types/api/PayResponse";

export const OrderService = Api.injectEndpoints({
  endpoints: (build) => ({
    orders: build.query<ListResponse<Order>, ListParams>({
      query: (params) => ({
        url: `/orders`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "ORDERS", params: arg },
        "ORDERS",
      ],
    }),
    getOrder: build.query<SingleItemResponse<Order>, string>({
      query: (orderId) => ({
        url: `orders/${orderId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "ORDERS", params: arg },
        "ORDERS",
      ],
    }),

    updateOrder: build.mutation<SingleItemResponse<Order>, Order>({
      query: (order) => ({
        url: `/orders/${order.id}`,
        method: "PUT",
        body: order,
      }),
      invalidatesTags: ["ORDERS"],
    }),

    payOrder: build.mutation<
      PayResponse,
      { orderId: string; continueUrl: string }
    >({
      query: ({ orderId, continueUrl }) => ({
        url: `/orders/${orderId}/pay`,
        method: "POST",
        body: {
          continue_url: continueUrl,
        },
      }),
      invalidatesTags: ["ORDERS"],
    }),

    payOrderByCard: build.mutation<
      PayResponse,
      { orderId: string; continueUrl: string }
    >({
      query: ({ orderId, continueUrl }) => ({
        url: `/orders/${orderId}/pay-by-card`,
        method: "POST",
        body: {
          continue_url: continueUrl,
        },
      }),
      invalidatesTags: ["ORDERS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyOrdersQuery,
  useOrdersQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useUpdateOrderMutation,
  usePayOrderByCardMutation,
  usePayOrderMutation,
} = OrderService;
