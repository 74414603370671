import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMeQuery } from "src/services/AuthService";
import { useCheckoutMutation, useGetCartQuery } from "src/services/CartService";

const Submit = () => {
  const cartId = useSelector<any>((store) => store.cart) as string;
  const { currentData: cartResponse, isFetching } = useGetCartQuery(
    cartId ?? skipToken
  );
  const cart = cartResponse?.data;

  const { isError, isFetching: meIsFetching } = useMeQuery();

  const { pathname } = useLocation();
  const [checkout] = useCheckoutMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!meIsFetching) {
      if (!isError) {
        if (!isFetching) {
          checkout({ cartId: cartId });
          navigate("/cart/payment/" + cartId);
        }
      } else {
        const searchParams = createSearchParams({
          to: pathname,
        }).toString();

        navigate({ pathname: "/login", search: searchParams });
      }
    }
  }, [isFetching, cart, isError, meIsFetching]);

  return <div></div>;
};

export default Submit;
