import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CFormInput,
  CInputGroup,
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
  CRow,
  CCol,
  CFormCheck,
} from "@coreui/react-pro";
import CreateSubdomain from "./domains/CreateSubdomain";
import CreateDomain, { CheckDomain } from "./domains/CreateDomain";
import RegisterDomain from "./domains/RegisterDomain";
import { Order } from "src/types/Order";
import { useAddDomainToCartMutation } from "src/services/CartService";
import "./Accordion.scss";
import { useState } from "react";

interface AddDomainProps {
  cart: Order;
}

const AddDomain = ({ cart }: AddDomainProps) => {
  const subjectable = cart.subjectable;
  const [activeKey, setActiveKey] = useState<number>(1);
  const [addDomainToCart] = useAddDomainToCartMutation();

  const handleAccordionClick = (e: React.MouseEvent, itemKey: number) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (activeKey !== itemKey) {
      setActiveKey(itemKey);
    }
  };

  const handleOnSubmit = async (form: CheckDomain) => {
    try {
      await addDomainToCart({
        cartId: cart.id,
        domainAction: {
          name: form.name,
          action: 0,
          ...subjectable,
        },
      }).unwrap();
    } catch (e) {}
  };

  return (
    <CCard className="mt-4">
      <CCardBody>
        <h5>Wybierz adres strony</h5>

        <CAccordion activeItemKey={activeKey} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader className="accordion-no-arrow">
              <CRow className="w-100">
                <CCol xs={8} className="d-flex align-items-center">
                  Darmowa subdomena
                </CCol>
                <CCol xs={4} className="text-end">
                  <span>0 zł</span>
                  <br />
                  <small className="text-muted">/ miesiąc</small>
                </CCol>
              </CRow>
            </CAccordionHeader>
            <CAccordionBody>
              <CreateSubdomain onSubmit={handleOnSubmit} />
            </CAccordionBody>
          </CAccordionItem>

          <CAccordionItem itemKey={2}>
            <CAccordionHeader className="accordion-no-arrow">
              <CRow className="w-100">
                <CCol xs={8} className="d-flex align-items-center">
                  Twoja domena
                </CCol>
                <CCol xs={4} className="text-end">
                  <span>0 zł</span>
                  <br />
                  <small className="text-muted">/ miesiąc</small>
                </CCol>
              </CRow>
            </CAccordionHeader>
            <CAccordionBody>
              <CreateDomain onSubmit={handleOnSubmit} />
            </CAccordionBody>
          </CAccordionItem>

          <CAccordionItem itemKey={3}>
            <CAccordionHeader className="accordion-no-arrow">
              <CRow className="w-100">
                <CCol xs={8} className="d-flex align-items-center">
                  Kup domenę
                </CCol>
                <CCol xs={4} className="text-end">
                  <span>100 zł</span>
                  <br />
                  <small className="text-muted">/ rok</small>
                </CCol>
              </CRow>
            </CAccordionHeader>
            <CAccordionBody>
              <RegisterDomain cart={cart} subjectable={subjectable} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>
  );
};

export default AddDomain;
