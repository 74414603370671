import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import Swal from "sweetalert2";
import { useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import DataTable from "src/components/DataTable/DataTable";
import {
  useDeleteEmailMutation,
  useEmailsQuery,
} from "src/services/EmailService";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import { skipToken } from "@reduxjs/toolkit/query";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);
  const { websiteId } = useParams();

  const [deleteEmail] = useDeleteEmailMutation();
  const { currentData: servicesResponse, isFetching } = useEmailsQuery(
    websiteId ? { websiteId: websiteId, params: params } : skipToken
  );
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const handleOnDelete = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmail(id);
      }
    });
  };

  const navigate = useNavigate();

  const columns = [
    { field: "address", headerName: "Adres", flex: 1 },
    {
      field: "quota",
      headerName: "Rozmiar",
      width: 150,
      renderCell: ({ value, row }: any) => {
        const quota = row.quota;
        const usage = row.usage;
        const procent = (usage / quota) * 100;

        return (
          <CCol>
            <CProgress value={procent}>
              {usage} / {quota}
            </CProgress>
          </CCol>
        );
      },
    },
    {
      field: "actions",
      headerName: "Akcje",
      width: 126,
      renderCell: ({ row: { id, status } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <Link
              to={`/websites/${websiteId}/emails/${id}/edit`}
              className="btn btn-primary"
              color="primary"
            >
              Edytuj
            </Link>
            <button
              onClick={() => handleOnDelete(id)}
              className="btn btn-danger text-white"
              color="primary"
            >
              Usuń
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <Link
            to={`/websites/${websiteId}/emails/create`}
            className="btn btn-primary"
          >
            Dodaj nowe konto email
          </Link>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
