import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddPlanToCartMutation,
  useCreateWebsiteConfigMutation,
  useGetCartQuery,
} from "src/services/CartService";

import { useGetWebsiteQuery } from "src/services/WebsiteService";
import { Subjectable } from "src/types/Subjectable";

const AddPlanToCart = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [addPlanToCart] = useAddPlanToCartMutation();

  const cartId = useSelector<any>((store) => store.cart) as string;
  const templateId = searchParams.get("template_id");
  const planId = searchParams.get("plan_id");
  const interval = searchParams.get("interval");

  const { currentData: cartResponse } = useGetCartQuery(cartId ?? skipToken);

  const cart = cartResponse?.data;

  useEffect(() => {
    if (cart) {
      if (templateId) {
        addPlanToCart({
          cartId: cart.id,
          planId: planId,
          interval: interval,
        });

        navigate("/cart");
      }
    }
  }, [cart, templateId]);

  return <></>;
};

export default AddPlanToCart;
