import { CCol, CFormInput, CInputGroup, CRow } from "@coreui/react-pro";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LoginRequest } from "src/types/api/LoginRequest";
import { CardFormRequest } from "src/types/CardFormRequest";
import "./CardForm.scss";

interface CardFormProps {
  token: string;
  signature: string;
  onSuccess: () => void;
  onFail: (error: any) => void;
  setLetsPay: (fn: () => void) => void;
}

const CardForm = ({
  token,
  signature,
  onSuccess,
  onFail,
  setLetsPay,
}: CardFormProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dictionary = {
    cardHolderLabel: "Imię i nazwisko",
    cardNumberLabel: "Numer karty",
    cvvLabel: "Kod CVV",
    expDateLabel: "Data wygaśnięcia karty",
    payButtonCaption: "Zapłać",
    threeDSAuthMessage: "Weryfikacja",
  };

  useEffect(() => {
    document.addEventListener("paid", function () {
      onSuccess();
    });

    document.addEventListener("payment_failed", function (e) {
      onFail(e);
    });
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://sandbox.przelewy24.pl/inchtml/ajaxPayment/ajax.js?token=${token}`;
    script.addEventListener("load", function () {
      dispatchEvent(new Event("load"));
    });

    const localScript = document.createElement("script");
    localScript.innerHTML = `function success (id) {
        console.log(id);
      document.dispatchEvent(new Event('paid'))
    }; 
    
    function fail (error) {
        console.log(error);
      document.dispatchEvent(new Event('payment_failed'))
    
    };`;
    document.body.appendChild(localScript);
    document.head.appendChild(script);
  }, [token, signature]);

  const buttonClick = () => {
    if (ref.current) {
      const button = ref.current.querySelector("#przelewy24RPCCardForm button");
      (button as HTMLButtonElement)?.click();
    }
  };

  const methods = useForm<CardFormRequest>();
  const { watch } = methods;
  const form = watch();

  useEffect(() => {
    Object.entries(form).forEach(([key, value]) => {
      ref.current?.querySelector(`#${key}`)?.setAttribute("value", value);
    });
  }, [form]);

  useEffect(() => {
    setLetsPay(() => buttonClick);
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new MutationObserver((mutations) => {
      const link = ref.current?.querySelector("a");
      if (link) {
        link.click();
      }
    });

    observer.observe(ref.current, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      {token && signature && (
        <>
          <FormProvider {...methods}>
            <CRow>
              <CCol>
                <CFormInput
                  {...methods.register("P24_cardHolder")}
                  floatingLabel="Imię i nazwisko*"
                  floatingClassName="mb-3"
                  placeholder="Imię i nazwisko"
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md={6}>
                <CFormInput
                  {...methods.register("P24_cardNumber")}
                  floatingLabel="Numer karty*"
                  floatingClassName="mb-3"
                  placeholder="Numer karty"
                />
              </CCol>
              <CCol md={3}>
                <CInputGroup>
                  <CFormInput
                    {...methods.register("P24_expMonth")}
                    floatingLabel="MM*"
                    placeholder="MM"
                  />
                  <CFormInput
                    {...methods.register("P24_expYear")}
                    floatingLabel="RR*"
                    placeholder="RR"
                  />
                </CInputGroup>
              </CCol>
              <CCol md={3}>
                <CFormInput
                  {...methods.register("P24_cardCVV")}
                  floatingLabel="CVV*"
                  placeholder="CVV"
                />
              </CCol>
            </CRow>
          </FormProvider>
          <div
            ref={ref}
            id="P24FormContainer"
            data-dictionary={JSON.stringify(dictionary)}
            data-sign={signature}
            data-successcallback="success"
            data-failurecallback="fail"
          ></div>
        </>
      )}
    </>
  );
};

CardForm.propTypes = {};

CardForm.defaultProps = {};

export default CardForm;
