import { Domain } from "src/types/Domain";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { ListParams } from "src/types/api/ListParams";
import { AttachDomainRequest } from "src/types/api/AttachDomainRequest";

export const DomainService = Api.injectEndpoints({
  endpoints: (build) => ({
    domains: build.query<
      ListResponse<Domain>,
      { websiteId: string; params: ListParams }
    >({
      query: ({ websiteId, params }) => ({
        url: `/websites/${websiteId}/domains`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "DOMAINS", params: arg },
        "DOMAINS",
      ],
    }),

    getDomain: build.query<SingleItemResponse<Domain>, string>({
      query: (id) => ({
        url: `domains/${id}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "DOMAINS", params: arg },
        "DOMAINS",
      ],
    }),

    createDomain: build.mutation<
      SingleItemResponse<Domain>,
      { websiteId: string; domain: Domain }
    >({
      query: ({ websiteId, domain }) => ({
        url: `/websites/${websiteId}/domains`,
        body: domain,
        method: "POST",
      }),

      invalidatesTags: ["DOMAINS"],
    }),

    setDefault: build.mutation<null, string>({
      query: (id: string) => ({
        url: `/domains/${id}/set-as-default`,
        method: "POST",
      }),

      invalidatesTags: ["DOMAINS"],
    }),

    deleteDomain: build.mutation<null, string>({
      query: (id: string) => ({
        url: `/domains/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["DOMAINS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDomainsQuery,
  useLazyDomainsQuery,
  useGetDomainQuery,
  useLazyGetDomainQuery,
  useSetDefaultMutation,
  useDeleteDomainMutation,
  useCreateDomainMutation,
} = DomainService;
