import { cilPlus, cilPuzzle, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CRow,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import constants from "src/constants";
import {
  useAddServiceToCartMutation,
  useRemoveCartItemMutation,
} from "src/services/CartService";
import { Order } from "src/types/Order";
import { OrderItem } from "src/types/OrderItem";
import { Service as ServiceType } from "src/types/Service";
import { Subjectable } from "src/types/Subjectable";
import { Website } from "src/types/Website";

interface ServiceProps {
  service: ServiceType;
  cart: Order;
}
interface SelectSubjectable {
  index: number;
}
const Service = ({ service, cart }: ServiceProps) => {
  const [addServiceToCart] = useAddServiceToCartMutation();
  const [removeCartItem] = useRemoveCartItemMutation();

  const itemWithPlan = cart.items.find(
    (item) => item.orderable_type === "App\\Models\\Plan"
  );

  const interval = itemWithPlan?.meta?.interval;

  const itemWithService = cart.items.find(
    (item) =>
      item.orderable_id === service.id &&
      item.orderable_type === "App\\Models\\Service"
  );

  const handleAddToCart = (service: ServiceType) => {
    addServiceToCart({
      cartId: cart.id,
      serviceId: service.id,
    });
  };

  const handleRemoveFromCart = (item: OrderItem) => {
    removeCartItem({
      cartId: cart.id,
      itemId: item.id,
    });
  };
  /*
1. odfiltruj subjectable, które mają tę usługę w koszyku,
2. odfiltruj subjectable, jeśli usługa jest cykliczna lub per page i subjectable nie ma website_params,
3. Odfiltruj subjectable, jeśli usługa jest cykliczna i subjectable jest website i website ma juz tę usługę kiedyś wykupioną
  */

  return (
    <>
      <CCard
        className={
          "mt-4 w-100 d-flex flex-column " +
          (itemWithService ? "outline-success outline-2px" : "")
        }
      >
        <CCardBody className="flex-grow-1">
          <CRow>
            <CCol xs={8}>
              <h5>
                <CIcon
                  icon={cilPuzzle}
                  className="mr-1"
                  width={24}
                  color="#f0f0f0"
                />{" "}
                {service.name}
              </h5>
            </CCol>
            <CCol xs={4} className="text-end">
              {service.type == constants.SERVICE_TYPE_RECURING &&
                (interval == 2 ? (
                  <>
                    {service.price_month} zł <br />{" "}
                    <small className="text-muted">/ miesiąc</small>
                  </>
                ) : (
                  <>
                    {service.price_year} zł <br />{" "}
                    <small className="text-muted">/ rok</small>
                  </>
                ))}

              {service.type == constants.SERVICE_TYPE_PER_PAGE && (
                <>
                  {service.price_per_page} zł <br />{" "}
                  <small className="text-muted">/ strona</small>
                </>
              )}

              {service.type == constants.SERVICE_TYPE_STANDARD && (
                <> {service.price} zł</>
              )}
            </CCol>
            <CCol xs={12}>
              <p className="text-muted pt-3 pb-3 mb-0">{service.description}</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter className="text-center">
          {itemWithService ? (
            <button
              className="btn btn-ghost-danger w-100"
              onClick={() => handleRemoveFromCart(itemWithService)}
            >
              <CIcon icon={cilTrash} className="mr-1" /> Usunąć z koszyka
            </button>
          ) : (
            <button
              className="btn btn-ghost-primary w-100"
              onClick={() => handleAddToCart(service)}
            >
              <CIcon icon={cilPlus} className="mr-1" /> Dodaj do zamówienia
            </button>
          )}
        </CCardFooter>
      </CCard>
    </>
  );
};

export default Service;
