import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCreateWebsiteConfigMutation,
  useGetCartQuery,
} from "src/services/CartService";

import RegisterDomain from "./domains/RegisterDomain";
import { useEffect, useState } from "react";
import { Subjectable } from "src/types/Subjectable";
import { CCard, CCardBody, CCardHeader } from "@coreui/react-pro";

const AddDomainToCart = () => {
  const cartId = useSelector<any>((store) => store.cart) as string;
  const { currentData: cartResponse } = useGetCartQuery(cartId ?? skipToken);
  const navigate = useNavigate();
  const cart = cartResponse?.data;
  const [searchParams] = useSearchParams();
  const websiteId = searchParams.get("website_id");
  const [subjectable, setSubjectable] = useState<Subjectable>();

  const websiteConfigSubjectable =
    cart?.subjectable_type == "App\\Models\\WebsiteConfig"
      ? cart.subjectable
      : null;

  useEffect(() => {
    if (websiteId) {
      setSubjectable({
        subjectable_id: websiteId,
        subjectable_type: "App\\Models\\Website",
      });
    } else {
      if (websiteConfigSubjectable) {
        setSubjectable(websiteConfigSubjectable);
      }
    }
  }, [websiteConfigSubjectable, websiteId]);

  const handleOnSubmit = () => {
    navigate("/cart");
  };
  return cart ? (
    <CCard className="mt-4">
      <CCardBody>
        <h5>Zarejestruj domenę</h5>
        <RegisterDomain
          cart={cart}
          onSubmit={handleOnSubmit}
          subjectable={subjectable}
        />
      </CCardBody>
    </CCard>
  ) : (
    <></>
  );
};

export default AddDomainToCart;
