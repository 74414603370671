import { Domain } from "src/types/Domain";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Email } from "src/types/Email";
import { ListParams } from "src/types/api/ListParams";

export const EmailService = Api.injectEndpoints({
  endpoints: (build) => ({
    emails: build.query<
      ListResponse<Email>,
      { websiteId: string; params: ListParams }
    >({
      query: ({ websiteId, params }) => ({
        url: `websites/${websiteId}/emails`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "EMAILS", params: arg },
        "EMAILS",
      ],
    }),
    getEmail: build.query<SingleItemResponse<Email>, string>({
      query: (templateId) => ({
        url: `emails/${templateId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "EMAILS", params: arg },
        "EMAILS",
      ],
    }),
    createEmail: build.mutation<
      SingleItemResponse<Email>,
      { websiteId: string; email: Email }
    >({
      query: ({ websiteId, email }) => ({
        url: `/websites/${websiteId}/emails`,
        body: email,
        method: "POST",
      }),

      invalidatesTags: ["EMAILS"],
    }),
    updateEmail: build.mutation<SingleItemResponse<Email>, Email>({
      query: (email: Email) => ({
        url: `/emails/${email.id}`,
        body: email,
        method: "PATCH",
      }),

      invalidatesTags: ["EMAILS"],
    }),

    deleteEmail: build.mutation<null, string>({
      query: (id: string) => ({
        url: `/emails/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["EMAILS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useEmailsQuery,
  useLazyEmailsQuery,
  useGetEmailQuery,
  useLazyGetEmailQuery,
  useCreateEmailMutation,
  useUpdateEmailMutation,
  useDeleteEmailMutation,
} = EmailService;
