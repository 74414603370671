import { cilArrowRight } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
} from "@coreui/react-pro";
import { useContext } from "react";
import { Link } from "react-router-dom";
import CartContext from "src/context/cart.context";
import { Order } from "src/types/Order";

interface SummaryProps {
  order: Order;
  onClick: () => void;
}
const PaymentSummary = ({ order, onClick }: SummaryProps) => {
  const { link } = useContext(CartContext);

  return (
    <CCard>
      <CCardBody>
        <h5>Podsumowanie</h5>
        <hr />

        {order.items.map((item) => (
          <CRow className="mb-2">
            <CCol xs={8}>
              <span className="text-muted">{item.name}</span>
            </CCol>
            <CCol xs={4} className="text-end">
              {item.price} zł
              {item.meta?.interval == 2 && " / mc"}
              {item.meta?.interval == 3 && " / rok"}
            </CCol>
          </CRow>
        ))}
        <hr />
        <CRow>
          <CCol xs={7}>
            <h6>Do zapłaty teraz</h6>
          </CCol>
          <CCol xs={5} className="text-end">
            <h6>{order.price} zł brutto</h6>
          </CCol>
        </CRow>
        <div className="d-grid gap-2">
          <button className="btn btn-primary mt-3 w-100" onClick={onClick}>
            Dalej <CIcon icon={cilArrowRight} />
          </button>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default PaymentSummary;
