import { cilLockLocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react-pro";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import MaskedInput from "src/components/MaskedInput";
import useDisableButton from "src/hooks/disableButtonHook";
import { useMeQuery, useUpdateMeMutation } from "src/services/AuthService";
import { User } from "src/types/User";
import Swal from "sweetalert2";

const Index = () => {
  const methods = useForm<User>();
  const {
    formState: { errors },
  } = methods;
  const isCompany = methods.watch("is_company");
  const { currentData: meResponse } = useMeQuery();
  const me = meResponse?.data;
  const [meUpdate] = useUpdateMeMutation();

  const disabledParams = useDisableButton();

  useEffect(() => {
    if (me) {
      methods.reset(me);
    }
  }, []);

  const handleOnSubmit = async (form: User) => {
    try {
      methods.clearErrors();
      await meUpdate(form).unwrap();
      Swal.fire({
        icon: "success",
        title: "Dane zostały zmienione",
      });
    } catch (e) {
      const error = e as FetchBaseQueryError;

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };
  return (
    <CRow>
      <CCol>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
            <CRow>
              <CCol md={6}>
                <CInputGroup className="mb-3">
                  <CFormInput
                    placeholder="Imię"
                    invalid={!!errors.first_name}
                    feedbackInvalid={errors.first_name?.message}
                    {...methods.register("first_name")}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CFormInput
                    placeholder="Nazwisko"
                    invalid={!!errors.last_name}
                    feedbackInvalid={errors.last_name?.message}
                    {...methods.register("last_name")}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <span className="input-group-text">@</span>
                  <CFormInput
                    placeholder="Email"
                    invalid={!!errors.email}
                    feedbackInvalid={errors.email?.message}
                    {...methods.register("email")}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <span className="input-group-text">
                    <CIcon icon={cilLockLocked} />
                  </span>
                  <CFormInput
                    type="password"
                    placeholder="Hasło"
                    invalid={!!errors.password}
                    feedbackInvalid={errors.password?.message}
                    {...methods.register("password")}
                  />
                </CInputGroup>
                <CInputGroup className="mb-4">
                  <span className="input-group-text">
                    <CIcon icon={cilLockLocked} />
                  </span>
                  <CFormInput
                    type="password"
                    invalid={!!errors.password_confirmation}
                    feedbackInvalid={errors.password_confirmation?.message}
                    placeholder="Powtórz hasło"
                    {...methods.register("password_confirmation")}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CFormCheck
                    {...methods.register("is_company")}
                    invalid={!!errors.is_company}
                    feedbackInvalid={errors.is_company?.message}
                    label="Czy firma?"
                  />
                </CInputGroup>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    {...disabledParams}
                  >
                    Zapisz
                  </button>
                </div>
              </CCol>

              {isCompany && (
                <CCol md={6}>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Nazwa firmy"
                      invalid={!!errors.company_name}
                      feedbackInvalid={errors.company_name?.message}
                      {...methods.register("company_name")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="NIP"
                      invalid={!!errors.tax_id}
                      feedbackInvalid={errors.tax_id?.message}
                      {...methods.register("tax_id")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Ulica"
                      invalid={!!errors.street}
                      feedbackInvalid={errors.street?.message}
                      {...methods.register("street")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Numer budynku"
                      invalid={!!errors.building_number}
                      feedbackInvalid={errors.building_number?.message}
                      {...methods.register("building_number")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Numer lokalu"
                      invalid={!!errors.apartment_number}
                      feedbackInvalid={errors.apartment_number?.message}
                      {...methods.register("apartment_number")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <MaskedInput
                      mask="00-000"
                      placeholder="Kod pocztowy"
                      invalid={!!errors.postal_code}
                      feedbackInvalid={errors.postal_code?.message}
                      lazy={false}
                      {...methods.register("postal_code")}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Miasto"
                      invalid={!!errors.city}
                      feedbackInvalid={errors.city?.message}
                      {...methods.register("city")}
                    />
                  </CInputGroup>
                </CCol>
              )}
            </CRow>
          </form>
        </FormProvider>
      </CCol>
    </CRow>
  );
};

export default Index;
