import { CAlert, CCard, CCardBody, CCol, CRow } from "@coreui/react-pro";
import masterCard from "../../assets/images/master-card.png";
import visa from "../../assets/images/visa.png";
import { useMeQuery } from "src/services/AuthService";
import CardForm from "src/components/CardForm";
import { Order } from "src/types/Order";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  usePayOrderByCardMutation,
  usePayOrderMutation,
} from "src/services/OrderService";

interface PaymentBoxProps {
  order: Order;
  setLetsPay: (fn: () => void) => void;
}

const PaymentBox = ({ order, setLetsPay }: PaymentBoxProps) => {
  const { currentData: meResponse } = useMeQuery();
  const me = meResponse?.data;
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState<any>();
  const [payOrder] = usePayOrderMutation();
  const [payOrderByCard] = usePayOrderByCardMutation();

  const handlePayOrder = async () => {
    if (order) {
      try {
        const res = await payOrder({
          orderId: order.id,
          continueUrl: `${process.env.REACT_APP_APP_URL}/cart/pay/${order.id}/thank-you`,
        }).unwrap();
        document.location.href = res.link;
      } catch (e) {}
    }
  };

  const handlePayCard = async () => {
    if (order) {
      try {
        const res = await payOrderByCard({
          orderId: order.id,
          continueUrl: `${process.env.REACT_APP_APP_URL}/cart/payment/${order.id}/thank-you`,
        }).unwrap();
      } catch (e: any) {
        setTransaction(e.data.transaction);
      }
    }
  };

  const callbackSuccess = () => {
    setError(null);
    if (order) navigate(`/cart/payment/${order.id}/thank-you`);
  };
  const dictionary = {
    cardHolderLabel: "Imię i nazwisko",
    cardNumberLabel: "Numer karty",
    cvvLabel: "Kod CVV",
    expDateLabel: "Data wygaśnięcia karty",
    payButtonCaption: "Zapłać",
    threeDSAuthMessage: "Weryfikacja",
  };

  const [error, setError] = useState<any>();
  const callbackFail = (error: any) => {
    setError("Wystąpił błąd podczas płatności. Spróbuj ponownie za chwilę.");
  };

  useEffect(() => {
    if (order && me) {
      if (!me.has_active_card && !transaction) {
        handlePayCard();
      }
    }
  }, [order, me?.has_active_card, transaction]);

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <h5>Karta płatnicza</h5>
          </CCol>
          <CCol>
            <div
              className="d-flex justify-content-end"
              style={{ height: "25px" }}
            >
              <img src={masterCard} alt="payment" className="mr-1" />
              <img src={visa} alt="payment" />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            {me?.has_active_card ? (
              <p>Karta aktywna</p>
            ) : (
              transaction && (
                <CardForm
                  onFail={callbackFail}
                  onSuccess={callbackSuccess}
                  token={transaction.token}
                  signature={transaction.signature}
                  setLetsPay={setLetsPay}
                />
              )
            )}
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            {error && (
              <CAlert color="danger" className="mt-3">
                {error}
              </CAlert>
            )}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default PaymentBox;
