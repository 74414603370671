import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useCreateCartMutation,
  useGetCartQuery,
} from "src/services/CartService";
import { setCartId } from "src/store/reducers/cart";

import {
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react-pro";
import AddDomain from "./AddDomain";
import Summary from "./Summary";
import CartContext from "src/context/cart.context";
import Navbar from "./Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export const Cart = () => {
  const dispatch = useDispatch();
  const cartId = useSelector<any>((store) => store.cart) as string;
  const [createCartMutation] = useCreateCartMutation();
  const [searchParams] = useSearchParams();
  const websiteId = searchParams.get("website_id");
  const templateId = searchParams.get("template_id");
  const location = useLocation();

  const getStep = (pathname: string) => {
    if (location.pathname === "/cart") {
      return 0;
    } else if (location.pathname === "/cart/checkout") {
      return 1;
    } else {
      return 2;
    }
  };
  const step = getStep(location.pathname);

  const {
    currentData: cartResponse,
    isFetching,
    isError,
  } = useGetCartQuery(cartId ? cartId : skipToken);

  const cart = cartResponse?.data;

  const [link, setLink] = useState<string>();

  const createCart = async () => {
    try {
      const res = await createCartMutation({
        websiteId: websiteId,
        templateId: templateId,
      }).unwrap();
      dispatch(setCartId(res.data.id));
    } catch (e) {}
  };

  if (isError) {
    dispatch(setCartId(null));
  }

  useEffect(() => {
    if (!cartId) {
      if (!isFetching) {
        if (!cart) {
          createCart();
        }
      }
    }
  }, [cartId, isFetching, cart]);

  const steps = ["Dane", "Dane", "Dane"];

  return (
    <>
      <Navbar />
      <CContainer>
        <CartContext.Provider value={{ link: link, setLink: setLink }}>
          <CRow className="row justify-content-md-center">
            <CCol md={6} className="mt-4 md-4">
              <Stepper activeStep={step}>
                <Step>
                  <Link to="/cart">
                    <StepLabel>Koszyk</StepLabel>
                  </Link>
                </Step>
                <Step>
                  <Link to="/cart/checkout">
                    <StepLabel>Twoje dane</StepLabel>
                  </Link>
                </Step>
                <Step>
                  <StepLabel>Płatność</StepLabel>
                </Step>
              </Stepper>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12} className="pt-4">
              <Outlet />
            </CCol>
          </CRow>
        </CartContext.Provider>
      </CContainer>
    </>
  );
};
