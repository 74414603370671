import React from 'react'
import { CFooter } from '@coreui/react-pro'

const AppFooter = () => {
  return (
    <CFooter className="px-4">

    </CFooter>
  )
}

export default React.memo(AppFooter)
