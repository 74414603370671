import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { Container } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { usePlansQuery } from "src/services/PlanService";
import { useTemplatesQuery } from "src/services/TemplateService";
import { DefaultListParams } from "src/types/api/ListParams";

const Generator = () => {
  const { currentData: templatesResponse } = useTemplatesQuery({
    ...DefaultListParams,
    per_page: 10000,
  });
  const templates = templatesResponse?.data;

  const { currentData: plansResponse } = usePlansQuery({
    ...DefaultListParams,
    per_page: 10000,
  });
  const plans = plansResponse?.data;

  const methods = useForm<any>();
  const { watch } = methods;
  const value = watch();
  const queryString = createSearchParams(value).toString();
  const navigate = useNavigate();

  const link = `${process.env.REACT_APP_APP_URL}/cart/plan?${queryString}`;

  const handleOnClose = () => {
    navigate("/plans");
  };

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      <CModalHeader>
        <h5>Generator linków do pakietów</h5>
      </CModalHeader>
      <CModalBody>
        <FormProvider {...methods}>
          <form>
            <CInputGroup className="mb-3">
              <CFormSelect {...methods.register("plan_id")}>
                <option>Wybierz pakiet</option>
                {plans?.map((plan) => (
                  <option value={plan.id}>{plan.name}</option>
                ))}
              </CFormSelect>
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CFormSelect {...methods.register("template_id")}>
                <option>Wybierz szablon</option>

                {templates?.map((template) => (
                  <option value={template.id}>{template.name}</option>
                ))}
              </CFormSelect>
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CFormSelect {...methods.register("interval")}>
                <option value="2">Miesiąc</option>
                <option value="3">Rok</option>
              </CFormSelect>
            </CInputGroup>
            {value.plan_id && value.template_id && value.interval && (
              <CCard>
                <CCardBody>
                  <code>{link}</code>
                </CCardBody>
              </CCard>
            )}
          </form>
        </FormProvider>
      </CModalBody>
      <CModalFooter className="mt-5">
        <button onClick={handleOnClose} className="btn btn-danger text-white">
          Zamknij
        </button>
      </CModalFooter>
    </CModal>
  );
};

export default Generator;
