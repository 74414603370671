import {
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CRow,
} from "@coreui/react-pro";
import { FormControl } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDomainsQuery } from "src/services/DomainService";
import { Email } from "src/types/Email";
import { DefaultListParams } from "src/types/api/ListParams";

interface FormProps {
  onSubmit: any;
  editMode?: boolean;
}
const Form = ({ onSubmit, editMode }: FormProps) => {
  const { websiteId } = useParams();
  const methods = useFormContext<Email>();
  const {
    formState: { errors },
  } = methods;
  const { currentData: domainsForEmailResponse } = useDomainsQuery(
    websiteId ? { websiteId: websiteId, params: DefaultListParams } : skipToken
  );
  const domains = domainsForEmailResponse?.data;

  const { register } = methods;
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <CFormLabel>Adres</CFormLabel>
      <CInputGroup>
        <CFormInput
          {...register("user")}
          invalid={!!errors.user}
          disabled={!!editMode}
        />
        <span className="input-group-text">@</span>
        <CFormSelect
          {...register("domain_id")}
          invalid={!!errors.domain_id}
          disabled={!!editMode}
        >
          {domains?.map((domain) => (
            <option key={domain.id} value={domain.id}>
              {domain.name}
            </option>
          ))}
        </CFormSelect>
        <div className="invalid-feedback">{errors.domain_id?.message}</div>
        <div className="invalid-feedback">{errors.user?.message}</div>
      </CInputGroup>
      <CFormLabel className="mt-2">Rozmiar</CFormLabel>
      <CInputGroup>
        <CFormInput
          {...register("quota")}
          invalid={!!errors.quota}
          feedbackInvalid={errors.quota?.message}
        />
        <span className="input-group-text">MB</span>
      </CInputGroup>

      <CFormLabel className="mt-3">Hasło</CFormLabel>
      <CFormInput
        type="password"
        {...register("password")}
        invalid={!!errors.password}
        feedbackInvalid={errors.password?.message}
      />

      <CFormLabel className="mt-3">Powtórz hasło</CFormLabel>
      <CFormInput
        type="password"
        {...register("password_confirmation")}
        invalid={!!errors.password_confirmation}
        feedbackInvalid={errors.password_confirmation?.message}
      />
    </form>
  );
};

export default Form;
