import {
  CFormCheck,
  CFormInput,
  CFormLabel,
  CInputGroup,
} from "@coreui/react-pro";
import { useFormContext } from "react-hook-form";
import { DomainPrice } from "src/types/DomainPrice";
import { Template } from "src/types/Template";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps) => {
  const methods = useFormContext<DomainPrice>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CFormLabel className="mt-3">Rozszeżenie</CFormLabel>
      <CFormInput
        {...register("extension")}
        invalid={!!errors.extension}
        feedbackInvalid={errors.extension?.message}
      />
      <CFormCheck {...register("active")} label="Aktywny?" className="mt-3" />

      <CFormLabel className="mt-3">Cena rejestracji</CFormLabel>
      <CFormInput
        {...register("register_price")}
        invalid={!!errors.register_price}
        feedbackInvalid={errors.register_price?.message}
      />

      <CFormLabel className="mt-3">Cena odnowienia</CFormLabel>
      <CFormInput
        {...register("renewal_price")}
        invalid={!!errors.renewal_price}
        feedbackInvalid={errors.renewal_price?.message}
      />
      <CFormLabel className="mt-3">Cena transferu</CFormLabel>
      <CFormInput
        {...register("transfer_price")}
        invalid={!!errors.transfer_price}
        feedbackInvalid={errors.transfer_price?.message}
      />
    </form>
  );
};

export default Form;
