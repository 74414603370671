import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react-pro";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMeQuery } from "src/services/AuthService";
import { CheckoutRequest } from "src/types/api/CheckoutRequest";
import Register from "../pages/register/Register";
import { useCheckoutMutation, useGetCartQuery } from "src/services/CartService";
import { useSelector } from "react-redux";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import CartContext from "src/context/cart.context";
import { IMaskMixin } from "react-imask";
import MaskedInput from "src/components/MaskedInput";
import PaymentBox from "./PaymentBox";
import PaymentSummary from "./PaymentSummary";
import {
  useGetOrderQuery,
  useUpdateOrderMutation,
} from "src/services/OrderService";
import { Order } from "src/types/Order";

const Payment = () => {
  const { currentData: meResponse, isFetching } = useMeQuery();

  const navigate = useNavigate();
  const me = meResponse?.data;
  const location = useLocation();
  const methods = useForm<Order>();
  const isCompany = methods.watch("is_company");
  const { orderId } = useParams();
  const { currentData: orderResponse } = useGetOrderQuery(orderId ?? skipToken);
  const order = orderResponse?.data;
  const [updateOrder] = useUpdateOrderMutation();
  const {
    reset,
    watch,
    formState: { errors },
  } = methods;

  const form = watch();

  useEffect(() => {
    if (!isFetching && !me) {
      navigate({
        pathname: "/login",
        search: createSearchParams({
          to: location.pathname,
        }).toString(),
      });
    }
  }, [me, isFetching]);

  const [paymentFunction, setPaymentFunction] = useState<(() => void) | null>(
    null
  );

  const handleOnClick = async () => {
    try {
      const res = await updateOrder(form).unwrap();
      if (paymentFunction) {
        paymentFunction();
      }
    } catch (e) {
      const error = e as FetchBaseQueryError;

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  useEffect(() => {
    reset(order);
  }, [order]);

  return (
    <CRow>
      <CCol md={8}>
        {order && (
          <PaymentBox
            order={order}
            setLetsPay={(fn: () => void) => setPaymentFunction(fn)}
          />
        )}
        <FormProvider {...methods}>
          <CCard className="mt-4">
            <CCardBody>
              {me && (
                <form onSubmit={methods.handleSubmit(() => {})}>
                  <h5>Wypełnij swoje dane</h5>
                  <CRow>
                    <CCol md={6}>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          floatingLabel="Imię"
                          floatingClassName="mb-3"
                          placeholder="Imię"
                          invalid={!!errors.first_name}
                          feedbackInvalid={errors.first_name?.message}
                          {...methods.register("first_name")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          floatingLabel="Nazwisko"
                          floatingClassName="mb-3"
                          placeholder="Nazwisko"
                          invalid={!!errors.last_name}
                          feedbackInvalid={errors.last_name?.message}
                          {...methods.register("last_name")}
                        />
                      </CInputGroup>

                      <CInputGroup className="mb-3">
                        <CFormCheck
                          className="mb-3"
                          {...methods.register("is_company")}
                          invalid={!!errors.is_company}
                          feedbackInvalid={errors.is_company?.message}
                          label="Czy firma?"
                        />
                      </CInputGroup>
                    </CCol>

                    {isCompany && (
                      <CCol md={6}>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="Nazwa firmy"
                            floatingClassName="mb-3"
                            placeholder="Nazwa firmy"
                            invalid={!!errors.company_name}
                            feedbackInvalid={errors.company_name?.message}
                            {...methods.register("company_name")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="NIP"
                            floatingClassName="mb-3"
                            placeholder="NIP"
                            invalid={!!errors.tax_id}
                            feedbackInvalid={errors.tax_id?.message}
                            {...methods.register("tax_id")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="Ulica"
                            floatingClassName="mb-3"
                            placeholder="Ulica"
                            invalid={!!errors.street}
                            feedbackInvalid={errors.street?.message}
                            {...methods.register("street")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="Numer budynku"
                            floatingClassName="mb-3"
                            placeholder="Numer budynku"
                            invalid={!!errors.building_number}
                            feedbackInvalid={errors.building_number?.message}
                            {...methods.register("building_number")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="Numer lokalu"
                            floatingClassName="mb-3"
                            placeholder="Numer lokalu"
                            invalid={!!errors.apartment_number}
                            feedbackInvalid={errors.apartment_number?.message}
                            {...methods.register("apartment_number")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <MaskedInput
                            mask="00-000"
                            floatingLabel="Kod pocztowy"
                            floatingClassName="mb-3"
                            placeholder="Kod pocztowy"
                            invalid={!!errors.postal_code}
                            feedbackInvalid={errors.postal_code?.message}
                            lazy={false}
                            {...methods.register("postal_code")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            floatingLabel="Miasto"
                            floatingClassName="mb-3"
                            placeholder="Miasto"
                            invalid={!!errors.city}
                            feedbackInvalid={errors.city?.message}
                            {...methods.register("city")}
                          />
                        </CInputGroup>
                      </CCol>
                    )}
                  </CRow>
                </form>
              )}
            </CCardBody>
          </CCard>
        </FormProvider>
      </CCol>
      <CCol md={4}>
        {order && <PaymentSummary order={order} onClick={handleOnClick} />}
      </CCol>
    </CRow>
  );
};

export default Payment;
