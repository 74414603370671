import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import { useSelector } from "react-redux";
import {
  useGetCartQuery,
  useRemoveCartItemMutation,
} from "src/services/CartService";
import { OrderItem } from "src/types/OrderItem";
import { Subjectable } from "src/types/Subjectable";
import AddDomain from "./AddDomain";
import { Link } from "react-router-dom";
import Services from "./Services";
import { skipToken } from "@reduxjs/toolkit/query";
import { Website } from "src/types/Website";
import { useContext, useEffect } from "react";
import CartContext from "src/context/cart.context";
import IntervalSwitch from "./IntervalSwitch";
import Plan from "./Plan";
import Summary from "./Summary";

export const Items = () => {
  const cartId = useSelector<any>((store) => store.cart) as string;
  const { currentData: cartResponse } = useGetCartQuery(cartId ?? skipToken);
  const cart = cartResponse?.data;
  const { link, setLink } = useContext(CartContext);

  useEffect(() => {
    setLink("/cart/checkout");
  }, []);

  return (
    <div>
      {cart && (
        <CRow>
          <CCol md={8}>
            <Plan cart={cart} />
            <AddDomain cart={cart} />
            {cart.subjectable && <Services cart={cart} />}
          </CCol>
          <CCol md={4}>
            <Summary cart={cart} />
          </CCol>
        </CRow>
      )}
    </div>
  );
};
