export interface ListMeta {
  per_page: number;
  total: number;
  current_page: number;
  from: number;
  to: number;
  last_page: number;
}
export const DefaultMeta = {
  total: 0,
  per_page: 0,
  current_page: 0,
  from: 0,
  to: 0,
  last_page: 0,
};
