import {
  CFormCheck,
  CFormInput,
  CFormLabel,
  CInputGroup,
} from "@coreui/react-pro";
import { useFormContext } from "react-hook-form";
import constants from "src/constants";
import { Service } from "src/types/Service";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps) => {
  const methods = useFormContext<Service>();
  const { watch } = methods;
  const form = watch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CFormLabel className="mt-3">Nazwa</CFormLabel>
      <CFormInput
        {...register("name")}
        invalid={!!errors.name}
        feedbackInvalid={errors.name?.message}
      />
      <CFormCheck
        {...register("public")}
        label="Czy publiczny?"
        className="mt-3"
      />
      <CFormCheck
        type="radio"
        {...register("type")}
        value={constants.SERVICE_TYPE_STANDARD}
        label="Standard"
      />
      <CFormCheck
        type="radio"
        {...register("type")}
        value={constants.SERVICE_TYPE_RECURING}
        label="Cykliczna"
      />
      <CFormCheck
        type="radio"
        {...register("type")}
        value={constants.SERVICE_TYPE_PER_PAGE}
        label="Za stronę"
      />
      {form.type == constants.SERVICE_TYPE_RECURING && (
        <>
          <CFormLabel className="mt-3">Cena miesięczna</CFormLabel>
          <CFormInput
            {...register("price_month")}
            invalid={!!errors.price_month}
            feedbackInvalid={errors.price_month?.message}
          />

          <CFormLabel className="mt-3">Cena roczna</CFormLabel>
          <CFormInput
            {...register("price_year")}
            invalid={!!errors.price_year}
            feedbackInvalid={errors.price_year?.message}
          />
        </>
      )}
      {form.type == constants.SERVICE_TYPE_PER_PAGE && (
        <>
          <CFormLabel className="mt-3">Cena za podstronę</CFormLabel>
          <CFormInput
            {...register("price_per_page")}
            invalid={!!errors.price_per_page}
            feedbackInvalid={errors.price_per_page?.message}
          />
        </>
      )}
      {form.type == constants.SERVICE_TYPE_STANDARD && (
        <>
          <CFormLabel className="mt-3">Cena stała</CFormLabel>
          <CFormInput
            {...register("price")}
            invalid={!!errors.price}
            feedbackInvalid={errors.price?.message}
          />
        </>
      )}
    </form>
  );
};

export default Form;
