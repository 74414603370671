import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { useGetPlanQuery } from "src/services/PlanService";
import {
  useGetServiceQuery,
  useUpdateServiceMutation,
} from "src/services/ServiceService";
import { useGetTemplateQuery } from "src/services/TemplateService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import Form from "./Form";
import { FormProvider, useForm } from "react-hook-form";
import { Service } from "src/types/Service";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import { useEffect } from "react";

const Update = () => {
  const { id } = useParams();
  const { currentData: serviceResponse } = useGetServiceQuery(id ?? skipToken);
  const service = serviceResponse?.data;

  const methods = useForm<Service>();
  const [updateService] = useUpdateServiceMutation();
  const { reset, watch, setError, clearErrors } = methods;
  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/services");
  };
  const form = watch();

  useEffect(() => {
    reset(service);
  }, [service]);
  const handleOnSubmit = async () => {
    try {
      clearErrors();
      const res = await updateService(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {service && (
        <>
          <CModalHeader>
            <h5>Usługa {service.name}</h5>
          </CModalHeader>
          <CModalBody>
            <FormProvider {...methods}>
              <Form onSubmit={handleOnSubmit} />
            </FormProvider>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnSubmit} className="btn btn-primary">
              Zapis
            </button>
            <button
              onClick={handleOnClose}
              className="btn btn-danger text-white"
            >
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Update;
