import { LoggedUser } from "src/types/LoggedUser";
import { SingleItemResponse } from "../types/api/Response";
import { User } from "../types/User";
import Api from "./Api";
import { LoginRequest } from "../types/api/LoginRequest";
import { Order } from "src/types/Order";
import { OrderItem } from "src/types/OrderItem";
import { WebsiteConfig } from "src/types/WebsiteConfig";

import { DomainAction } from "src/types/DomainAction";

import { Subjectable } from "src/types/Subjectable";

export const CartService = Api.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query<SingleItemResponse<Order>, string>({
      query: (cartId) => `/cart/${cartId}`,
      providesTags: ["CART"],
    }),

    createCart: build.mutation<
      SingleItemResponse<Order>,
      { websiteId: string | null; templateId: string | null }
    >({
      query: ({ websiteId, templateId }) => ({
        url: `/cart`,
        method: "POST",
        body: {
          website_id: websiteId,
          template_id: templateId,
        },
      }),

      invalidatesTags: ["CART"],
    }),

    createWebsiteConfig: build.mutation<SingleItemResponse<WebsiteConfig>, any>(
      {
        query: ({ cartId, templateId }) => ({
          url: `/cart/${cartId}/website-config`,
          body: { template_id: templateId },
          method: "POST",
        }),
        invalidatesTags: ["CART"],
      }
    ),

    addWebsiteCart: build.mutation<SingleItemResponse<OrderItem>, any>({
      query: ({ cartId, websiteId }) => ({
        url: `/cart/${cartId}/website/${websiteId}`,

        method: "POST",
      }),
      invalidatesTags: ["CART"],
    }),

    addDomainToCart: build.mutation<
      SingleItemResponse<OrderItem>,
      { cartId: string; domainAction: DomainAction }
    >({
      query: ({ cartId, domainAction }) => ({
        url: `/cart/${cartId}/domain`,
        body: domainAction,

        method: "POST",
      }),
      invalidatesTags: ["CART"],
    }),

    addServiceToCart: build.mutation<
      SingleItemResponse<OrderItem>,
      {
        cartId: string;
        serviceId: string;
      }
    >({
      query: ({ cartId, serviceId }) => ({
        url: `/cart/${cartId}/service/${serviceId}`,

        method: "POST",
      }),
      invalidatesTags: ["CART"],
    }),

    addPlanToCart: build.mutation<SingleItemResponse<OrderItem>, any>({
      query: ({
        cartId,
        planId,
        subjectableType,
        subjectableId,
        interval,
      }) => ({
        url: `/cart/${cartId}/plan/${planId}`,
        body: {
          subjectable_type: subjectableType,
          subjectable_id: subjectableId,
          interval: interval,
        },
        method: "POST",
      }),
      invalidatesTags: ["CART"],
    }),
    removeCartItem: build.mutation<null, any>({
      query: ({ cartId, itemId }) => ({
        url: `/cart/${cartId}/item/${itemId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CART"],
    }),
    checkout: build.mutation<SingleItemResponse<Order>, { cartId: string }>({
      query: ({ cartId }) => ({
        url: `/cart/${cartId}/checkout`,
        method: "POST",
      }),
      invalidatesTags: ["CART", "ORDERS"],
    }),
    checkDomain: build.mutation<any, { name: string }>({
      query: ({ name }) => ({
        url: `/cart/domain/check`,
        body: { name: name },
        method: "POST",
      }),
    }),

    checkDomainAvailability: build.mutation<any, { name: string }>({
      query: ({ name }) => ({
        url: `/cart/domain/check-availability`,
        body: { name: name },
        method: "POST",
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCartQuery,
  useCreateCartMutation,
  useAddPlanToCartMutation,
  useCreateWebsiteConfigMutation,
  useLazyGetCartQuery,
  useAddWebsiteCartMutation,
  useRemoveCartItemMutation,
  useCheckoutMutation,
  useCheckDomainAvailabilityMutation,
  useCheckDomainMutation,
  useAddDomainToCartMutation,
  useAddServiceToCartMutation,
} = CartService;
