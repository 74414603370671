interface IntervalSwitchProps {
  interval: number;
  discount: number;
  onChange: (interval: number) => void;
}
const IntervalSwitch = ({
  interval,
  discount,
  onChange,
}: IntervalSwitchProps) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      <button
        type="button"
        className={`btn ${interval == 2 ? "btn-primary" : "btn-light"}`}
        onClick={() => onChange(2)}
      >
        Miesięcznie
      </button>
      <button
        type="button"
        className={`btn ${interval == 3 ? "btn-primary" : "btn-light"}`}
        onClick={() => onChange(3)}
      >
        Rocznie{" "}
        {discount && (
          <span className="badge text-bg-success text-white">
            -{discount} %
          </span>
        )}
      </button>
    </div>
  );
};

export default IntervalSwitch;
