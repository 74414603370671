import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react-pro";
import constants from "src/constants";
import { useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import DataTable, { TableBadge } from "src/components/DataTable/DataTable";
import {
  useDeleteDomainMutation,
  useDomainsQuery,
  useSetDefaultMutation,
} from "src/services/DomainService";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import Swal from "sweetalert2";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/query";

const Index = () => {
  const { websiteId } = useParams();
  const [params, setParams] = useState<ListParams>(DefaultListParams);
  const [setDefault] = useSetDefaultMutation();

  const { currentData: servicesResponse, isFetching } = useDomainsQuery(
    websiteId ? { websiteId: websiteId, params: params } : skipToken
  );
  const [deleteDomain] = useDeleteDomainMutation();
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const handleOnDelete = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDomain(id);
      }
    });
  };

  const handleOnSetDefault = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
    }).then((result) => {
      if (result.isConfirmed) {
        setDefault(id);
      }
    });
  };

  const columns = [
    { field: "name", headerName: "Nazwa", flex: 1 },
    {
      field: "type",
      headerName: "Typ",
      flex: 1,
      renderCell: ({ value }: any) => {
        if (value == 0) {
          return <span className="badge bg-primary">subdomena</span>;
        } else if (value == 1) {
          return <span className="badge bg-secondary">domana zewnętrzna</span>;
        } else if (value == 2) {
          return (
            <span className="badge bg-success">zarejestrowana domena</span>
          );
        }
      },
    },

    {
      field: "renewable",
      headerName: "Automatyczne odnawianie",
      flex: 1,
      renderCell: ({ value, row }: any) => {
        if (row.type == 2) {
          return value ? (
            <span className="badge bg-primary">Tak</span>
          ) : (
            <span className="badge bg-danger">Nie</span>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      field: "expire_at",
      headerName: "Wygasa",
      width: 150,
      renderCell: ({ value, row }: any) =>
        row.type == 2 ? moment(value).format("YYYY-MM-DD HH:mm") : "-",
    },
    {
      field: "actions",
      headerName: "Akcje",
      width: 200,
      renderCell: ({ row: { id, status, type, connected } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <button
              className="btn btn-primary"
              disabled={connected}
              onClick={() => handleOnSetDefault(id)}
            >
              Ustaw jako domyślną
            </button>
            <button
              onClick={() => handleOnDelete(id)}
              className={
                "btn btn-danger text-white" +
                (type != constants.DOMAIN_TYPE_EXTERNAL ? " disabled" : "")
              }
              color="primary"
            >
              Usuń
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <CButtonGroup>
            <Link to="/cart/domain" className="btn btn-primary">
              Zarejestruj nową domenę
            </Link>
            <Link
              to={`/websites/${websiteId}/domains/create`}
              className="btn btn-primary"
            >
              Dodaj własną domenę
            </Link>
          </CButtonGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
