import { cilArrowRight } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
} from "@coreui/react-pro";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CartContext from "src/context/cart.context";
import { useMeQuery } from "src/services/AuthService";
import { useCheckoutMutation } from "src/services/CartService";
import { Order } from "src/types/Order";

interface SummaryProps {
  cart: Order;
}
const Summary = ({ cart }: SummaryProps) => {
  const { link } = useContext(CartContext);
  const { currentData: meResponse } = useMeQuery();
  const [checkout] = useCheckoutMutation();
  const me = meResponse?.data;
  const navigate = useNavigate();

  return (
    <>
      {link && (
        <CCard>
          <CCardBody>
            <h5>Podsumowanie</h5>
            <hr />

            {cart.items.map((item) => (
              <CRow className="mb-2">
                <CCol xs={8}>
                  <span className="text-muted">{item.name}</span>
                </CCol>
                <CCol xs={4} className="text-end">
                  {item.price} zł
                  {item.meta?.interval == 2 && " / mc"}
                  {item.meta?.interval == 3 && " / rok"}
                </CCol>
              </CRow>
            ))}
            <hr />
            <CRow>
              <CCol xs={7}>
                <h6>Do zapłaty teraz</h6>
              </CCol>
              <CCol xs={5} className="text-end">
                <h6>{cart.price} zł brutto</h6>
              </CCol>
            </CRow>
            <div className="d-grid gap-2">
              <Link to={"/cart/submit"}>
                <button className="btn btn-primary mt-3 w-100">
                  Dalej <CIcon icon={cilArrowRight} />
                </button>
              </Link>
            </div>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};

export default Summary;
