import { CFormInput } from "@coreui/react-pro";
import { IMaskMixin } from "react-imask";

const MaskedInput = IMaskMixin(({ inputRef, ...props }: any) => (
  <CFormInput
    {...props}
    ref={inputRef} // bind internal input
  />
));

export default MaskedInput;
