import {
  CButton,
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CNavLink,
} from "@coreui/react-pro";

import "./Navbar.scss";
import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-white border-bottom">
      <div className="container">
        <Link to="/" className="navbar-brand text-primary">
          <CIcon icon={cilArrowLeft} customClassName="nav-icon" width={16} />{" "}
          Wróć do panelu
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-coreui-toggle="collapse"
          data-coreui-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
