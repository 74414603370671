import { Plan } from "src/types/Plan";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Service } from "src/types/Service";
import { ListParams } from "src/types/api/ListParams";
import { Backup } from "src/types/Backup";
import { Notification } from "src/types/Notification";
import { NotificationListResponse } from "src/types/api/NotificationListMeta";

export const NotificationService = Api.injectEndpoints({
  endpoints: (build) => ({
    notifications: build.query<
      NotificationListResponse<Notification>,
      ListParams
    >({
      query: (params) => ({
        url: `/notifications`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "NOTIFICATIONS", params: arg },
        "NOTIFICATIONS",
      ],
    }),

    readNotification: build.mutation<SingleItemResponse<Notification>, string>({
      query: (notificationId) => ({
        url: `notifications/${notificationId}`,
        method: "GET",
      }),
      invalidatesTags: ["NOTIFICATIONS"],
    }),
  }),
  overrideExisting: false,
});

export const { useNotificationsQuery, useReadNotificationMutation } =
  NotificationService;
