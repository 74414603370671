import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { Website } from 'src/types/Website'

import { CBadge, CNavGroup, CNavItem, CNavLink, CNavTitle, CSidebarNav } from '@coreui/react-pro'
import { useWebsitesQuery } from 'src/services/WebsiteService'
import CIcon from '@coreui/icons-react'
import {
  cilGlobe,
  cilEnvelopeClosed,
  cilPencil,
  cilWindow,
  cilGlobeAlt,
  cilCart,
  cilSettings
} from '@coreui/icons'
import { useMeQuery } from 'src/services/AuthService'

export const AppSidebarNav = ({ items }) => {
  const { data: websitesResponse } = useWebsitesQuery();
  const websites = websitesResponse?.data;

  const { currentData: meResponse } = useMeQuery();
  const me = meResponse?.data;



  return (
    <CSidebarNav as={SimpleBar}>
      {websites && websites.map(website => (
        <CNavGroup key={website.id} toggler={<>
          {website.domain.name}
        </>
        }>
          <CNavItem>
            <CNavLink as={NavLink} to={`/websites/${website.id}/domains`}>
              <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />
              Domeny
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to={`/websites/${website.id}/emails`}>
              <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />
              Email
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href={'https://' + website.domain.name + '/wp-login.php'} target='_blank'>
              <CIcon icon={cilPencil} customClassName="nav-icon" />
              Edytuj stronę
            </CNavLink>
          </CNavItem>
        </CNavGroup>
      ))}
      <CNavTitle>Konto</CNavTitle>
      <CNavItem>
        <CNavLink as={NavLink} to="/orders">
          <CIcon icon={cilCart} customClassName="nav-icon" />
          Zamówienia
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink as={NavLink} to="/settings">
          <CIcon icon={cilSettings} customClassName="nav-icon" />
          Ustawienia konta
        </CNavLink>
      </CNavItem>
      {me?.admin && (
        <>
          <CNavTitle>System</CNavTitle>
          <CNavItem>
            <CNavLink as={NavLink} to="/plans">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Pakiety
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to="/plans/generator">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Generator linków
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to="/services">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Usługi
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to="/templates">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Szablony
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to="/domain-prices">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Cenniki domen
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={NavLink} to="/ovh">
              <CIcon icon={cilSettings} customClassName="nav-icon" />
              Logowanie do API OVH
            </CNavLink>
          </CNavItem>
        </>
      )}

    </CSidebarNav>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
