import { CCard, CCardBody } from "@coreui/react-pro";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";

const ThankYou = () => {
  const { orderId } = useParams();
  const { currentData: orderResponse } = useGetOrderQuery(orderId ?? skipToken);
  const order = orderResponse?.data;

  return (
    <CCard className="mt-3">
      <CCardBody>
        <h3 className="text-center">Dziękujemy za dokonanie płatności</h3>
        <code>{JSON.stringify(order)}</code>
      </CCardBody>
    </CCard>
  );
};

export default ThankYou;
