import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { FormProvider, useForm } from "react-hook-form";
import { RegisterRequest } from "src/types/api/RegisterRequest";
import { FormGroup } from "@mui/material";
import { useMeQuery, useRegisterMutation } from "src/services/AuthService";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDisableButton from "src/hooks/disableButtonHook";

const Register = () => {
  const methods = useForm<RegisterRequest>();
  const {
    formState: { errors },
  } = methods;
  const [register] = useRegisterMutation();
  const isCompany = methods.watch("is_company");
  const { currentData: meResponse, isFetching } = useMeQuery();
  const me = meResponse?.data;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const disableProps = useDisableButton();

  const redirect = searchParams.get("to") ?? "/";
  const handleOnSubmit = async (form: RegisterRequest) => {
    try {
      methods.clearErrors();
      await register(form).unwrap();
    } catch (e) {
      const error = e as FetchBaseQueryError;

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  if (me) {
    navigate(redirect, { replace: true });
  }

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <h1>Rejestracja</h1>
                    <p className="text-body-secondary">
                      Stwórz konto w sitely.pl
                    </p>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Imię"
                        invalid={!!errors.first_name}
                        feedbackInvalid={errors.first_name?.message}
                        {...methods.register("first_name")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Nazwisko"
                        invalid={!!errors.last_name}
                        feedbackInvalid={errors.last_name?.message}
                        {...methods.register("last_name")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <span className="input-group-text">@</span>
                      <CFormInput
                        placeholder="Email"
                        invalid={!!errors.email}
                        feedbackInvalid={errors.email?.message}
                        {...methods.register("email")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <span className="input-group-text">
                        <CIcon icon={cilLockLocked} />
                      </span>
                      <CFormInput
                        type="password"
                        placeholder="Hasło"
                        invalid={!!errors.password}
                        feedbackInvalid={errors.password?.message}
                        {...methods.register("password")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <span className="input-group-text">
                        <CIcon icon={cilLockLocked} />
                      </span>
                      <CFormInput
                        type="password"
                        invalid={!!errors.password_confirmation}
                        feedbackInvalid={errors.password_confirmation?.message}
                        placeholder="Powtórz hasło"
                        {...methods.register("password_confirmation")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CFormCheck
                        {...methods.register("is_company")}
                        invalid={!!errors.is_company}
                        feedbackInvalid={errors.is_company?.message}
                        label="Czy firma?"
                      />
                    </CInputGroup>
                    {isCompany && (
                      <>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Nazwa firmy"
                            invalid={!!errors.company_name}
                            feedbackInvalid={errors.company_name?.message}
                            {...methods.register("company_name")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="NIP"
                            invalid={!!errors.tax_id}
                            feedbackInvalid={errors.tax_id?.message}
                            {...methods.register("tax_id")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Ulica"
                            invalid={!!errors.street}
                            feedbackInvalid={errors.street?.message}
                            {...methods.register("street")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Numer budynku"
                            invalid={!!errors.building_number}
                            feedbackInvalid={errors.building_number?.message}
                            {...methods.register("building_number")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Numer lokalu"
                            invalid={!!errors.apartment_number}
                            feedbackInvalid={errors.apartment_number?.message}
                            {...methods.register("apartment_number")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Kod pocztowy"
                            invalid={!!errors.postal_code}
                            feedbackInvalid={errors.postal_code?.message}
                            {...methods.register("postal_code")}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CFormInput
                            placeholder="Miasto"
                            invalid={!!errors.city}
                            feedbackInvalid={errors.city?.message}
                            {...methods.register("city")}
                          />
                        </CInputGroup>
                      </>
                    )}

                    <div className="d-grid">
                      <button
                        className="btn btn-success"
                        type="submit"
                        {...disableProps}
                      >
                        Zarejestruj się
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Register;
